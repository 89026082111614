<template>
    <div>
        <ul class="nav nav-pills nav-fill">
            <li class="nav-item">
                <router-link
                    :to="{ name: 'home' }"
                    class="nav-link"
                    exact-active-class="active"
                    >Home</router-link
                >
            </li>
            <li class="nav-item">
                <router-link
                    :to="{ name: 'content-management-system-services' }"
                    class="nav-link"
                    exact-active-class="active"
                    active-class="active"
                    >CMS</router-link
                >
            </li>
            <li class="nav-item">
                <router-link
                    :to="{ name: 'contact-us-form' }"
                    class="nav-link"
                    exact-active-class="active"
                    active-class="active"
                    >Contact Us Form</router-link
                >
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'CoreNavbar',
}
</script>

<style></style>
