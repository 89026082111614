const routes = [
    {
        path: '/',
        component: () => import('../pages/index.vue'),
        name: 'home',
    },
    {
        path: '/home',
        component: () => import('../pages/index.vue'),
    },
    {
        path: '/contact-us-form',
        component: () => import('../pages/contact-us-form/index.vue'),
        name: 'contact-us-form',
    },
    {
        path: '/content-management-system',
        component: () => import('../pages/content-management-system/index.vue'),
        children: [
            {
                path: '',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/ContentManagementSystemServices.vue'
                    ),
                name: 'content-management-system-services',
            },
            {
                path: 'services/create',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/Services/ContentManagementSystemServicesCreate.vue'
                    ),
                name: 'content-management-system-services-create',
            },
            {
                path: 'services/:id/update',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/Services/ContentManagementSystemServicesUpdate.vue'
                    ),
                name: 'content-management-system-services-update',
            },
            {
                path: 'operational-offices',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/OperationalOffices/ContentManagementSystemOperationalOffices.vue'
                    ),
                name: 'content-management-system-operational-offices',
            },
            {
                path: 'testimonies',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/Testimonies/ContentManagementSystemTestimonies.vue'
                    ),
                name: 'content-management-system-testimonies',
            },
            {
                path: 'products',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/Products/ContentManagementSystemProducts.vue'
                    ),
                name: 'content-management-system-products',
            },
            {
                path: 'clients',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/Clients/ContentManagementSystemClients.vue'
                    ),
                name: 'content-management-system-clients',
            },
            {
                path: 'service-clients',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/ServiceClients/ServiceClientsList.vue'
                    ),
                name: 'content-management-system-service-clients',
            },
            {
                path: 'partners',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/Partners/ContentManagementSystemPartners.vue'
                    ),
                name: 'content-management-system-service-partners',
            },
            {
                path: 'gallery',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/Gallery/ContentManagementSystemGallery.vue'
                    ),
                name: 'content-management-system-service-gallery',
            },
            {
                path: 'investor-relations',
                component: () =>
                    import(
                        '../components/ContentManagementSystem/InvestorRelations/ContentManagementSystemInvestorRelations.vue'
                    ),
                name: 'content-management-system-investor-relations',
            },
        ],
    },
]

export default routes
