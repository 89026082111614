import Vue from 'vue'
import Vuex from 'vuex'
// import user from "./user";

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        loading: false,
    },

    getters: {
        LOADING(state) {
            return state.loading
        },
    },

    mutations: {
        SET_LOADING(state, payload = true) {
            state.loading = payload
        },
    },

    actions: {},
    modules: {
        // user
    },
})

export default store
