<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <core-navbar />
            </div>
        </div>
        <div class="row">
            <div class="col py-4">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import CoreNavbar from './components/Core/CoreNavbar.vue'
export default {
    name: 'App',
    components: { CoreNavbar },
    computed: {
        loading() {
            return this.$store.getters.LOADING
        },
    },
    watch: {
        loading() {
            if (this.loading === true) {
                this.$toast.clear()
                // this.$toast.info('Loading', {
                //     timeout: 1000,
                // })
            }
        },
    },
}
</script>

<style>
html {
    scroll-behavior: smooth;
}
</style>
