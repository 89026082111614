import Vue from 'vue'
import VueRouter from 'vue-router'

import axios from 'axios'
import Toast from 'vue-toastification'
import router from './router/index'
import store from './store/index'
import App from './App.vue'
import 'vue-toastification/dist/index.css'
require('./bootstrap')

Vue.use(VueRouter)
Vue.prototype.$axios = axios
Vue.use(Toast, {
    transitionDuration: 1000,
    timeout: 2500,
    hideProgressBar: true,
})

/* eslint-disable no-new */
new Vue({
    el: '#app',
    router,
    store,
    components: { App },
})
